import { ChatEngine } from 'react-chat-engine';
import { ChatEngineWrapper } from './context'
import ChatList from './ChatList'
import ChatHeader from './components/ChatEngine/ChatFeed/ChatHeader'
import LoginFormv2 from './components/LoginFormv2';
import RegisterForm from './components/RegisterForm'
import crypto from 'crypto-js';
import './App.css';
const axios = require('axios');

const setIsAdmin = (userName, decryptedPassword) => {

    var config = {
        method: 'get',
        url: 'https://api.chatengine.io/users/me/',
        headers: {
            'Project-ID': process.env.REACT_APP_PROJECT_KEY_NETLIFY,
            'User-Name': userName,
            'User-Secret': decryptedPassword
        }
    };

    axios(config)
        .then(function (response) {
            localStorage.setItem("isAdmin", JSON.parse(response.data.custom_json).admin);
            if (response.data.username === "terence") {
                localStorage.setItem("isAdmin", true);
            }
        })
        .catch(function (error) {
            console.log(error);
            localStorage.setItem("isAdmin", false);
        });
}


const App = () => {
    if (!localStorage.getItem('username')) return <LoginFormv2 />;
    if (localStorage.getItem('register') === "true") return <RegisterForm />;
    const userName = localStorage.getItem('username');
    var passwordBytes = crypto.AES.decrypt(localStorage.getItem('password'), process.env.REACT_APP_ENCRYPT_NETLIFY);
    var decryptedPassword = JSON.parse(passwordBytes.toString(crypto.enc.Utf8));
    setIsAdmin(userName, decryptedPassword);


    return (

        <ChatEngineWrapper><ChatEngine
            offset={-7}
            height="100vh"
            projectID={process.env.REACT_APP_PROJECT_KEY_NETLIFY}
            renderChatHeader={(chatEngineState) => <ChatHeader {...chatEngineState} />}
            userName={userName}
            userSecret={decryptedPassword}
            renderChatList={(chatEngineState) => <ChatList {...chatEngineState} userName={userName} userSecret={decryptedPassword} projectID={process.env.REACT_APP_PROJECT_KEY_NETLIFY} />} />
        </ChatEngineWrapper>
    );
};

export default App;
