import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import axios from 'axios';
import crypto from 'crypto-js';

export default function SignIn() {


    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const inputStyle = { WebkitBoxShadow: "0 0 0 1000px #1d202f inset" };
    const theme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        const authObject = { 'Project-ID': process.env.REACT_APP_PROJECT_KEY_NETLIFY, 'User-Name': username, 'User-Secret': password };

        try {
            await axios.get('https://api.chatengine.io/chats', { headers: authObject });
            localStorage.setItem('username', username);
            localStorage.setItem('password', crypto.AES.encrypt(JSON.stringify(password), process.env.REACT_APP_ENCRYPT_NETLIFY).toString());

            window.location.reload();
            setError('');
        } catch (err) {
            setError('Incorrect credentials.');
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box>
                        <img src='eruptlogo.png' alt="Eruptious Logo" class="erupt-login-logo" />
                    </Box>
                    <Typography component="h1" variant="h5">
                        Eruptious Games Chat
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} method="post" noValidate sx={{ mt: 1 }}>
                        <TextField
                            onChange={(e) => setUsername(e.target.value)}
                            margin="normal"
                            fullWidth
                            id="Username"
                            label="Username"
                            inputProps={{ style: inputStyle }}
                            name="Username"
                            autoComplete="Username"
                            autoFocus
                        />
                        <TextField
                            onChange={(e) => setPassword(e.target.value)}
                            margin="normal"
                            fullWidth
                            inputProps={{ style: inputStyle }}
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 3, mb: 2, background: "#1d202f", color: 'white', '&:hover': {
                                    color: '#1d202f',
                                    backgroundColor: 'white',
                                }
                            }}
                        >
                            Sign In
                        </Button>

                    </Box>
                    <h1>{error}</h1>
                </Box>
            </Container>
        </ThemeProvider >
    );
}