import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import axios from 'axios';
import FormControlLabel from '@mui/material/FormControlLabel';

const inputStyle = { WebkitBoxShadow: "0 0 0 1000px #1d202f inset" };
const theme = createTheme({
    palette: {
        mode: 'dark',
    },
});

export default function SignUp() {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [email, setEmail] = useState('');
    const [admin, setAdmin] = useState(false);


    const handleCheck = () => {
        if (!admin) {
            setAdmin(true);
        } else {
            setAdmin(false);
        }
    }

    const toLogin = () => {
        window.location.reload();
        localStorage.removeItem('username');
        localStorage.removeItem('password');
        localStorage.setItem('register', "false");
    }

    const backToChat = () => {
        window.location.reload();
        localStorage.setItem('register', "false");
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        var data = {
            "username": username,
            "secret": password,
            "email": email,
            "first_name": fname,
            "last_name": lname,
            "custom_json": "{\"admin\": " + admin + "}"
        };

        var config = {
            method: 'post',
            url: 'https://api.chatengine.io/users/',
            headers: {
                'PRIVATE-KEY': process.env.REACT_APP_PRIVATE_KEY_NETLIFY
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                window.location.reload();
                localStorage.setItem('register', "false");
            })
            .catch(function (error) {
                window.alert("Something went wrong!");
            });
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box>
                        <img src='eruptlogo.png' alt="Eruptious Logo" class="erupt-signup-logo" />
                    </Box>
                    <Typography component="h1" variant="h5">
                        Register New User
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    onChange={(e) => setFname(e.target.value)}
                                    autoComplete="given-name"
                                    name="firstName"
                                    required
                                    fullWidth
                                    inputProps={{ style: inputStyle }}
                                    id="firstName"
                                    label="First Name"
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    onChange={(e) => setLname(e.target.value)}
                                    required
                                    fullWidth
                                    inputProps={{ style: inputStyle }}
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    autoComplete="family-name"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    onChange={(e) => setUsername(e.target.value)}
                                    required
                                    fullWidth
                                    inputProps={{ style: inputStyle }}
                                    id="username"
                                    label="User Name"
                                    name="username"
                                    autoComplete="username"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    onChange={(e) => setEmail(e.target.value)}
                                    fullWidth
                                    id="email"
                                    inputProps={{ style: inputStyle }}
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    onChange={(e) => setPassword(e.target.value)}
                                    fullWidth
                                    name="password"
                                    inputProps={{ style: inputStyle }}
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={admin}
                                        onChange={handleCheck} />}
                                    label="Is Admin?"
                                />
                            </Grid>

                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 3, mb: 2, background: "#1d202f", color: 'white', '&:hover': {
                                    color: '#1d202f',
                                    backgroundColor: 'white',
                                }
                            }}
                        >
                            Sign Up
                        </Button>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link href="#" variant="body2" onClick={toLogin}>
                                    Already have an account? Sign in
                                </Link>
                            </Grid>

                        </Grid>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link href="#" onClick={backToChat}>
                                    Back to chat
                                </Link>
                            </Grid>
                        </Grid>

                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}