import React, { useContext } from 'react'

import { CloseOutlined } from '@ant-design/icons'
import { getOrCreateChat } from 'react-chat-engine'
import { ChatEngineContext } from 'react-chat-engine'

import ChatLink from './ChatLink'
import ChatGroup from './ChatGroup'

let styles = require('../styles.json');

const ChatList = props => {
    const { setActiveChat } = useContext(ChatEngineContext)
    const { chats } = useContext(ChatEngineContext)

    function hasReadLastMessage(chat) {
        var lastReadMessageID = -1
        chat.people.map(chat_person => {
            if (chat_person.person.username === props.userName) {
                lastReadMessageID = chat_person.last_read
            }
        })
        return !chat.last_message.id || lastReadMessageID === chat.last_message.id
    }

    function renderChannels() {
        const chatList = chats ? Object.values(chats) : []
        return chatList.map((chat, index) => {
            if (!chat.is_direct_chat) {
                return (
                    <ChatLink
                        key={`chat-${index}`}
                        title={`${chat.title}`}
                        bold={!hasReadLastMessage(chat)}
                        onClick={() => setActiveChatHelper(chat.id)}
                    />
                )
            } else {
                return <div key={`chat-${index}`} />
            }
        })
    }

    function returnNotMe(chat) {
        let username = ''
        chat.people.map(chat_person => {
            if (chat_person.person.username !== props.userName) {
                username = chat_person.person.username
            }
        })
        return username
    }

    function renderDirectMessages() {
        const chatList = chats ? Object.values(chats) : []
        return chatList.map((chat, index) => {
            if (chat.is_direct_chat) {
                return (
                    <ChatLink
                        key={`chat-${index}`}
                        title={`${returnNotMe(chat)}`}
                        bold={!hasReadLastMessage(chat)}
                        onClick={() => setActiveChatHelper(chat.id)}
                    />
                )
            } else {
                return <div key={`chat-${index}`} />
            }
        })
    }

    function setActiveChatHelper(id) {
        setActiveChat(id);
        hide();
    }

    function onChannelCreate(data) {
        const chat = { title: data.value }
        getOrCreateChat(props, chat, r => console.log('New Channel', r))
    }

    function onDirectMessageCreate(data) {
        const chat = {
            is_direct_chat: true,
            usernames: [data.value, props.userName]
        }
        getOrCreateChat(props, chat, r => console.log('New DM', r))
    }

    function hide() {
        let d = document.getElementsByClassName("ce-chat-list-mobile-option")[0];
        let x = d.getElementsByTagName("div")[1];
        x.style.display = 'none';
    }

    return (
        <div style={styles.chatList}>
            <CloseOutlined
                style={styles.closeIcon}
                onClick={() => hide()}
            />
            <div style={styles.titleContainer}>
                <ChatGroup
                    title='Channels'
                    placeholder='Create a group'
                    onSubmit={(data) => onChannelCreate(data)}
                />
            </div>

            <div style={styles.chatsContainer}>
                {renderChannels()}
            </div>

            <div style={styles.titleContainer}>
                <ChatGroup
                    title='Direct Messages'
                    placeholder='Type a username'
                    onSubmit={(data) => onDirectMessageCreate(data)}
                />
            </div>

            <div style={styles.chatsContainer}>
                {renderDirectMessages()}
            </div>
            <div
                style={{
                    ...styles.chatListButtonsContainer,
                }}>
                <button
                    onClick={() => {
                        localStorage.clear()
                        window.location.reload()
                    }
                    }
                    style={{
                        ...styles.link,
                        ...{
                            color: '#1d202f',
                            backgroundColor: 'white',
                            padding: '8px 10px',
                            borderRadius: '24px',
                            border: '0px solid white',
                            fontWeight: props.bold ? '900' : '500',
                            fontSize: props.bold ? '18px' : '17px',
                        }
                    }}
                >Sign Out</button>
                {localStorage.getItem("isAdmin") === "true" ? <button
                    onClick={() => {
                        if (localStorage.getItem("isAdmin")) {
                            localStorage.setItem("register", "true");
                            window.location.reload();
                        }

                    }
                    }
                    style={{
                        ...styles.link,
                        ...{
                            color: '#1d202f',
                            backgroundColor: 'white',
                            marginLeft: '10px',
                            padding: '8px 10px',
                            borderRadius: '24px',
                            border: '0px solid white',
                            fontWeight: props.bold ? '900' : '500',
                            fontSize: props.bold ? '18px' : '17px',
                        }
                    }}
                >Register new user</button>
                    : null}

            </div>
        </div >
    );
}

export default ChatList;